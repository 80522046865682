/**
 * Header component - display name of website
 */
import './Header.css'

function Header() {
    return (
        <div className="Header">
            arkost.in
        </div>
    )
}

export default Header;